import React, { useEffect, useState } from 'react'
import '../css/Dashboard.css'
import { NavLink ,useNavigate} from 'react-router-dom';
import Alert from '../Alert'
import Footer from '../Footer';
export default function Dashboard() {
    const host = process.env.REACT_APP_SERVER_HOST_URL;     // HOST address

    const navigate = useNavigate() ; // for redirection
    //state for userDetails.
    const [userDetails, setuserDetails] = useState({ name: "", email: "" })

    //function to get loggined user's details
    const getUserDetails = async (e) => {
        //API call to get  users details
        const response = await fetch(`${host}/api/auth/admin/getUser`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'auth-token': localStorage.getItem('token')
            },

        });
        const json = await response.json();

        if (json.success) {
            setuserDetails({ name: json.user.name, email: json.user.email })   // setting user data in userDetails state
        }
        else {
            alert("Internel Server Error !")
        }

    }

    //Runs only on the first render page to get user data
    useEffect(() => {
        if(!localStorage.getItem('token') || localStorage.getItem('userType')!=='admin')
           {
               navigate("/login")
           }
        getUserDetails();     // function to get Admin/user details

    }, [])

    //function to capitalize any string
    function capitalize(name) {
        return name.replace(/\b(\w)/g, s => s.toUpperCase());
    }
    return (
        <div style={{overflowY:"hidden", height:"100vh"}} id='background-image'>
            <div style={{padding: 16}} className='dashboard-top text-white'>Dashboard </div>
            <Alert />
            <div className='dashboard'>
                <div className="d-flex flex-column align-items-center border border-2 p-4 border-secondary">
                    <div className='profile ' >
                        <div className='profile-data'><span>Name: </span>{capitalize(userDetails.name.toLowerCase())}</div>
                        <div className='profile-data'><span>Email Id: </span>{userDetails.email.toLowerCase()}</div>
                    </div>
                    <div className="update">
                        <div style={{display: 'flex',justifyContent: 'center'}}>
                            <NavLink to="/admin/update-profile" state={{ userDetails }} className="" ><button type="button" className="btn btn-primary update-btn">Update Profile</button></NavLink>
                        </div>
                        <div style={{display: 'flex',justifyContent: 'center'}}>
                            <NavLink to="/admin/update-password" className="" ><button type="button" className="btn btn-primary update-btn">Update Password</button></NavLink>
                        </div>
                        <div style={{display: 'flex',justifyContent: 'center'}}>
                            <NavLink to="/admin/view-complaints" className="" ><button type="button" className="btn btn-primary update-btn">View Complaints</button></NavLink>
                        </div>
                        <div style={{display: 'flex',justifyContent: 'center'}}>
                            <NavLink to="/admin/manage-users" className="" ><button type="button" className="btn btn-primary update-btn">Manage users</button></NavLink>
                        </div>

                    </div>
                </div>
            </div>
        </div>

    )
}
